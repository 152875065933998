import React from "react"

const Logo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" baseProfile="full" className="w-7 md:w-12 h-7 md:h-12" viewBox="25 25 575 575" preserveAspectRatio="xMidYMid meet" id="svg_document" visibility="visible">
            <title id="svg_document_title" visibility="visible">favicon.svg</title>
            <defs id="svg_document_defs" visibility="visible"></defs>
            <g id="main_group" visibility="visible" fill="#737373">
            </g>
            <rect stroke="#737373" x="25px" height="550px" y="25px" id="rect1" strokeWidth="0px" width="75px" fill="#737373" transform=""></rect>
            <rect stroke="#737373" x="25px" height="75px" y="500px" id="rect2" strokeWidth="0px" width="550px" fill="#737373" transform=""></rect>
            <rect stroke="#737373" x="135px" height="75px" y="25px" id="rect4" width="440px" strokeWidth="0px" fill="#737373" transform=""></rect>
            <rect stroke="#737373" x="315px" height="370px" y="100px" id="rect5" width="75px" strokeWidth="0px" fill="#737373" transform=""></rect>
        </svg>
    )
  }
  
  export default Logo